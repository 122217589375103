import React from 'react'
import * as styles from 'src/css/social-links.module.css'
import ShareTwitter from 'src/images/share/twitter.inline.svg'
import ShareLinkedIn from 'src/images/share/linkedin.inline.svg'
import ShareEmail from 'src/images/share/email.inline.svg'
import {graphql, useStaticQuery} from 'gatsby'

const SocialLinks = (props) => {
  const {title, path, className, ...other} = props
  const { strapiGlobal: {siteName} } = useStaticQuery(query);

  return (
    <>
      <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(path)}&text=${encodeURIComponent(title)}`}
         target="_blank" rel="noreferrer" className={styles.socialLink}>
        <ShareTwitter />
      </a>
      <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(path)}`}
         target="_blank" rel="noreferrer" className={styles.socialLink}>
        <ShareLinkedIn />
      </a>
      <a href={`mailto:?subject=${encodeURIComponent(title)} - ${encodeURIComponent(siteName)}&body=${encodeURIComponent(path)}`}
         className={styles.socialLink}>
        <ShareEmail />
      </a>
    </>
  )
}

const query = graphql`
  query {
    strapiGlobal {
      siteName
    }
  }
`;

export default SocialLinks
