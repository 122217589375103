import React from 'react'
import * as styles from 'src/css/rich-text.module.css'
import clsx from 'clsx'

const RichText = (props) => {
  const {html, className, ...other} = props
  return (
    <div className={clsx(styles.richText, className)} dangerouslySetInnerHTML={{ __html: html }} {...other} />
  )
}

export default RichText
