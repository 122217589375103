// extracted by mini-css-extract-plugin
export var caseStudiesPosts = "post-module--case-studies-posts--34760 case-studies-index-module--postcard--24406";
export var content = "post-module--content--a6036";
export var featureImage = "post-module--feature-image--05fa0";
export var header = "post-module--header--b837f";
export var headerContent = "post-module--header-content--2471e";
export var main = "post-module--main--717b1";
export var post = "post-module--post--ebe14";
export var relatedContent = "post-module--related-content--6fa4b";
export var relatedContentInner = "post-module--related-content-inner--6a3c9";
export var socialLinks = "post-module--social-links--51338";
export var socialMedia = "post-module--social-media--e75db";